@import url('https://tangosquared.com/fonts/akrobat.css');
@import url('https://p.typekit.net/p.css?s=1&k=igm8sqo&ht=tk&f=30684.30685&a=32276116&app=typekit&e=css');
@font-face {
    font-family: 'agency-fb';
    src: url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'agency-fb';
    src: url('https://use.typekit.net/af/cf93ac/00000000000000003b9ae624/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/cf93ac/00000000000000003b9ae624/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff'),
        url('https://use.typekit.net/af/cf93ac/00000000000000003b9ae624/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            // background-image: url('../../resources/images/headerEvents.jpg'); // loaded dynamically
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: 0 0 -1rem 0;
        .sports {
            margin: 0 0.8rem 0 0;
            display: flex;
            align-items: center;
        }
        button {
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid #555;
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 1rem -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
}

.player {
    position: relative;
    padding: 0 0 10rem 0;
    header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .picture {
            flex: 1 0 25.6rem;
            max-width: 25.6rem;
            & > div {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .playerInfo {
            flex: 1;
            padding: 0 2rem;
            h1 {
                font-size: 4.6rem;
                line-height: 5rem;
                color: #fff;
                font-weight: 600;
                margin: 3rem 0 0 0;
            }
            ul.stats {
                padding: 0;
                margin: 2.5rem 0 0 0;
                list-style: none;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                li {
                    padding: 0 2.5rem 0 0;
                    margin: 0 2rem 0 0;
                    border-right: 1px solid rgba(228, 228, 228, 0.3);
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    white-space: nowrap;
                    font-weight: 500;
                    label {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                        display: block;
                        text-transform: uppercase;
                        color: #808191;
                        margin: 0 0 0.5rem 0;
                        font-weight: 600;
                    }
                    &:last-of-type {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
            }
            .contact {
                margin: 4.5rem 0 0 0;
                display: flex;
                flex-direction: column;
                a {
                    display: none;
                    background-position: left center;
                    padding: 0 0 0 3.5rem;
                    background-repeat: no-repeat;
                    margin: 0.4rem 0;
                    font-size: 1.5rem;
                    line-height: 2.4rem;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                a[href*='mailto'] {
                    display: inline-block;
                    background-image: url('../../resources/images/ico-email.svg');
                    background-size: 2.2rem auto;
                }
                a[href*='tel'] {
                    display: inline-block;
                    background-image: url('../../resources/images/ico-phone.svg');
                    background-size: 1.8rem auto;
                    background-position: 0.2rem center;
                }
            }
            .misc {
                p {
                    margin: 1rem 0 0 0;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
            }
            .school {
                margin: 3rem 0 0 0;
                font-size: 1.7rem;
                line-height: 2.5rem;
                color: #fff;
                font-weight: 300;
            }
        }
        .scores {
            display: flex;
            justify-content: flex-end;
        }
        .playerScore {
            text-align: center;
            &:nth-of-type(1) {
                margin-right: 6rem;
            }
            .score {
                margin: 3rem 0 0 0;
                font-size: 8.3rem;
                line-height: 8rem;
                font-weight: 600;
                color: rgba(128, 129, 145, 0.6);
            }
            p {
                font-size: 1.7rem;
                margin: 0.5rem 0;
                line-height: 2.4rem;
                color: #fff;
                font-weight: 600;
            }
            img {
                display: inline-block;
            }
        }
        .playerStats { flex:1 0 100%; max-width:calc(100% - 30rem);
            margin: 3.5rem 0;
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                list-style: none;
                li {
                    margin: 0.5rem;
                    padding: 1.2rem 2rem;
                    border-radius: 1.2rem;
                    background: rgba(36, 39, 49, 1);
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    font-weight: 500;
                    label {
                        color: #fff;
                        margin-right: 1rem;
                    }
                }
            }
        }
        .sportSelect{ flex:1 0 30rem; max-width:30rem; margin: 3.5rem 0; display:flex; justify-content:flex-end;
            .affiliates {
                width: 300px;
                height: 56px;
                border: 1px solid rgba(228, 228, 228, 0.1);
                border-radius: 12px;
            }
        }
    }

    .trainingapp {
        margin-top: 2rem;
        background: linear-gradient(346deg, rgba(197, 31, 54, 1) 0%, rgba(30, 47, 110, 1) 100%);
        padding: 2rem 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 2.4rem;
        max-width: 110rem;
        .img {
            flex: 1 0 13rem;
            max-width: 13rem;
            img {
                width: 100%;
                height: 100%;
                object-position: contain;
            }
        }
        .txt {
            text-align: center;
            padding: 0 3rem;
            flex: 1 0 33rem;
            max-width: 33rem;
            h2 {
                font-family: 'Akrobat', 'Poppins', sans-serif;
                color: #fff;
                margin: 0;
                text-align: center;
                font-size: 2rem;
                line-height: 3rem;
                text-transform: uppercase;
                letter-spacing: 0.2em;
            }
            p {
                font-family: 'agency-fb', 'Poppins', sans-serif;
                max-width: 30rem;
                font-size: 5rem;
                line-height: 5rem;
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                text-align: center;
            }
        }
        .cta {
            text-align: center;
            p {
                font-family: 'Akrobat', 'Poppins', sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #fff;
                margin: 0 auto 2rem auto;
                text-align: center;
                max-width: 35rem;
            }
            a {
                font-family: 'agency-fb', 'Poppins', sans-serif;
                text-align: center;
                min-width: 17rem;
                display: inline-block;
                text-transform: uppercase;
                color: #fff;
                font-size: 2rem;
                line-height: 2.4rem;
                padding: 1rem 2rem;
                font-weight: 600;
                border-radius: 2.2rem;
                height: 4.4rem;
                background: #bd1c2e;
                transition: all 0.3s;
                &:hover {
                    background: #1e2f6e;
                }
            }
        }
    }

    .about {
        margin-top: 1rem;
        p {
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: #fff;
            margin: 2rem 0;
            max-width: 67rem;
            font-weight: 300;
        }
        a {
            font-size: 1.6rem;
            line-height: 2.6rem;
            text-decoration: underline;
            color: rgba(255, 255, 255, 0.4);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            button {
                padding: 0;
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.4);
                &:hover {
                    background: transparent;
                    text-decoration: none;
                }
            }
        }
    }

    .details {
        display: flex;
        justify-content: flex-start;
        margin: 10rem -2.5rem 0 -2.5rem;
        & > div {
            flex: 1 0 33.33%;
            max-width: 33.33%;
            padding: 0 2.5rem;
            h2 {
                padding: 0 0 2.4rem 4.2rem;
                background-position: left top;
                background-repeat: no-repeat;
                border-bottom: 0.4rem solid rgba(228, 228, 228, 0.1);
                white-space: nowrap;
            }
            &.school h2 {
                background-image: url('../../resources/images/ico-coach.svg');
            }
            &.parent h2 {
                background-image: url('../../resources/images/ico-parent.svg');
            }
            &.social h2 {
                background-image: url('../../resources/images/ico-socialMedia.svg');
            }

            h3 {
                font-size: 1.4rem;
                line-height: 1.6rem;
                text-transform: uppercase;
                color: #808191;
                font-weight: 600;
                margin: 2.2rem 0 0.5rem 0;
            }
            p {
                font-size: 1.8rem;
                line-height: 2.4rem;
                color: #fff;
                font-weight: 500;
                margin: 0;
                a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &.social {
                h3 {
                    padding-left: 5rem;
                    margin: 3rem 0 0.5rem 0;
                    position: relative;
                    &:before {
                        content: '';
                        width: 2.4rem;
                        height: 2.4rem;
                        background-size: auto 2.4rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    &.facebook:before {
                        background-image: url('../../resources/images/sm-facebook.svg');
                    }
                    &.instagram:before {
                        background-image: url('../../resources/images/sm-instagram.svg');
                    }
                    &.twitter:before {
                        background-image: url('../../resources/images/sm-twitter.svg');
                    }
                    &.snapchat:before {
                        background-image: url('../../resources/images/sm-snapchat.svg');
                    }
                    &.tiktok:before {
                        background-image: url('../../resources/images/sm-tiktok.svg');
                    }
                }
                a {
                    padding-left: 5rem;
                }
            }
        }
    }

    .records {
        header {
            margin: 7rem 0 3rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                margin: 0;
            }
            button {
                color: #808191;
                text-decoration: underline;
                padding: 0;
                margin: 0;
                border: none;
                &:hover {
                    text-decoration: none;
                    background: transparent;
                }
            }
        }
        .recordsData {
            margin: 6rem 0 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            border-radius: 2.4rem;
            background: #242731;
            padding: 2.5rem 0 0 0;
            overflow: hidden;
            & > .label {
                flex: 1 0 100%;
                max-width: 100%;
                padding: 0 2.5rem;
                & > div {
                    background: #33363f;
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    margin: 0 0 3rem 0;
                    padding: 0.8rem 2.2rem;
                    border-radius: 1.6rem;
                    font-weight: 500;
                }
            }
            .record {
                flex: 1 0 33.33%;
                max-width: 33.33%;
                text-align: center;
                padding: 0 3rem 1rem 3rem;
                position: relative;
                margin: 0 0 4.5rem 0;
                &:after {
                    content: '';
                    width: 1px;
                    height: 10rem;
                    background: #3b3e48;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    margin-top: -5rem;
                }

                .icon {
                    height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 0.5rem 0;
                }
                h3 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1.9px;
                    margin: 0;
                }
                .speed .icon svg {
                    width: auto;
                    height: 2.5rem;
                    color: transparent;
                }
                .agility .icon svg {
                    width: auto;
                    height: 1.8rem;
                    color: transparent;
                }
                .explosion .icon svg {
                    width: auto;
                    height: 1.9rem;
                    color: transparent;
                }

                p {
                    font-size: 2.1rem;
                    line-height: 2.4rem;
                    margin: 2rem 0 0 0;
                    font-weight: 500;
                    color: #fff;
                }
                & > div > div > span {
                    display: block;
                }
                & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                    font-family: Poppins, sans-serif;
                    font-size: 5rem;
                    line-height: 3.5rem;
                    margin: 2rem 0;
                    color: #fff;
                    font-weight: 600;
                    position: relative;
                }
                .label {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: #fff;
                    display: block;
                }
                .play {
                    height: 4.4rem;
                    background: #3f8cff;
                    border-radius: 1.2rem;
                    display: inline-flex;
                    align-items: center;
                    margin: 2.5rem 0 0 0;
                    padding: 1rem 2.3rem;
                    transition: all 0.3s;
                    span {
                        width: 1.4rem;
                        height: 1.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 0.7rem 0 0;
                        svg {
                            display: block !important;
                            width: 1.4rem;
                            height: 1.4rem;
                            z-index: 3;
                            color: transparent;
                        }
                    }
                    &:hover {
                        background: #095cb5;
                    }
                }
            }
        }
    }

    .photos {
        header {
            margin: 7rem 0 3rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                margin: 0;
            }
            button {
                color: #808191;
                text-decoration: underline;
                padding: 0;
                margin: 0;
                border: none;
                &:hover {
                    text-decoration: none;
                    background: transparent;
                }
            }
        }
    }
    h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        color: #fff;
        margin: 3rem 0;
    }
}

.photosList {
    padding-bottom: 5rem;
    & > div > div > div > div {
        margin-bottom: 2.4rem;
        &:nth-of-type(4n + 1) {
            flex: 1 0 60%;
            max-width: 60%;
        }
        &:nth-of-type(4n + 2) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        &:nth-of-type(4n + 3) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        &:nth-of-type(4n + 4) {
            flex: 1 0 60%;
            max-width: 60%;
        }
    }
    .card {
        border-radius: 1rem;
        overflow: hidden;
        background: #32343a;
        position: relative;
        border: none;
        cursor: pointer;
        & > div {
            padding: 0;
        }
        & > div:nth-of-type(2) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 3rem 4rem 3rem;
            z-index: 2;
        }
        .meta {
            font-size: 1.8rem;
            line-height: 2.6rem;
            color: #fff;
            font-weight: 500;
        }
        .image {
            height: 35rem;
            img,
            video {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: top;
            }
            .gradient {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
            }
        }
        &:hover {
            & > div:nth-of-type(2) {
                &:after {
                    background-color: #095cb5;
                }
            }
        }
    }
}

.card.cardVideo {
    & > div:nth-of-type(2) {
        &:after {
            content: '';
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background: #3f8cff url(../../resources/images/ico-play.svg) no-repeat;
            background-position: 1.5rem center;
            background-size: 1.6rem auto;
            margin: 2rem 0 0 0;
            transition: all 0.3s;
        }
    }
}

/* mobile */
.mobileHeaderBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 33rem;
    display: none;
    .headerBackgroundImage {
        background-image: url('../../resources/images/headerEvents-mob.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.topNavi {
    display: flex;
    justify-content: space-between;
    margin: 0 -0.5rem 2.5rem -0.5rem;
    .buttonDiv {
        flex: 1 0 50%;
        max-width: 50%;
        padding: 0 0.5rem;
        button {
            border: none;
            width: 100%;
            height: 5.6rem;
            background: #242731;
            color: #a0a1ab;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.8rem 2rem 1.8rem 5.6rem;
            position: relative;
            text-align: left;
            font-weight: 500;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: '';
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                left: 1.7rem;
                top: 0;
                width: 3rem;
                height: 100%;
                opacity: 0.4;
            }
        }
        .events:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-document.svg');
            background-size: 1.8rem auto;
            background-position: 0.6rem center;
        }
        .tasks:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-tasks.svg');
            background-size: 3rem auto;
            background-position: center;
        }
    }
}

/* rwd */
@media only screen and (min-width: 1450px) {
    .photosList > div > div > div {
        & > div:nth-of-type(6n + 1) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        & > div:nth-of-type(6n + 2) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 3) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 4) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 5) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 6) {
            flex: 1 0 40%;
            max-width: 40%;
        }
    }
}

@media only screen and (min-width: 1700px) {
    .photosList > div > div > div {
        & > div:nth-of-type(8n + 1) {
            flex: 1 0 34%;
            max-width: 34%;
        }
        & > div:nth-of-type(8n + 2) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 3) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 4) {
            flex: 1 0 22%;
            max-width: 22%;
        }

        & > div:nth-of-type(8n + 5) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 6) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 7) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 8) {
            flex: 1 0 34%;
            max-width: 34%;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .player header .playerScore {
        .score {
            font-size: 7rem;
            line-height: 7rem;
        }
        p {
            font-size: 1.6rem;
        }
        &:nth-of-type(1) {
            margin-right: 4rem;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .player header {
        flex-wrap: wrap;
        justify-content: flex-start;
        .scores {
            flex: 1 0 100%;
            max-width: 100%;
            justify-content: flex-start;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .player .records .recordsData {
        padding: 3rem 0;
    }
}

@media only screen and (max-width: 1100px) {
    .player {
        header {
            .playerInfo h1 {
                margin-top: 3.5rem;
            }
            .playerStats {
                order: 2;
            }
            .sportSelect {
                order: 3;
            }
            .picture {
                flex: 1 0 18rem;
                max-width: 18rem;
                height: 18rem;
            }
        }
        .trainingapp {
            padding: 2rem 2rem;
            flex-wrap: wrap;
            .txt {
                flex: 1;
                max-width: 100%;
                padding: 0 0 0 3rem;
                p {
                    max-width: 100%;
                }
            }
            .cta {
                flex: 1 0 100%;
                max-width: 100%;
                margin-top: 2rem;
            }
        }
        .details {
            flex-direction: column;
            & > div {
                flex: auto;
                max-width: 40rem;
                margin-bottom: 8rem;
            }
        }
    }
}

@media only screen and (max-width: 1050px) {
    .player .records .recordsData {
        .record {
            p {
                font-size: 1.8rem;
                line-height: 2.2rem;
            }
            .label {
                font-size: 1.3rem;
                line-height: 1.6rem;
            }
            & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                font-size: 3.8rem;
                line-height: 3rem;
                &:before {
                    width: 2rem;
                    height: 2rem;
                    margin: 1.5rem 0 0 -2.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .player .records .recordsData .record {
        flex: 1 0 50%;
        max-width: 50%;
    }
    .photosList {
        & > div > div > div {
            margin-left: -0.8rem !important;
            margin-right: -0.8rem !important;
            & > div {
                margin-bottom: 0;
                &:nth-of-type(4n + 1),
                &:nth-of-type(4n + 2),
                &:nth-of-type(4n + 3),
                &:nth-of-type(4n + 4) {
                    flex: 1 0 100%;
                    max-width: 100%;
                }
                &:nth-of-type(3n + 2),
                &:nth-of-type(3n + 3) {
                    flex: 1 0 50%;
                    max-width: 50%;
                }
                & > div {
                    padding: 0 0.8rem !important;
                }
            }
        }
        .card {
            & > div:nth-of-type(2) {
                padding: 0 2rem 3rem 2rem;
            }
            .image {
                height: 30rem;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .player header{
        .playerStats{ order:3; max-width:100%; }
        .sportSelect{ order:2; flex:1 0 100%; max-width:100%; margin:3.5rem 0 0 0; justify-content:flex-start; }
    }
}


@media only screen and (max-width: 767px) {
    .player {
        .records .recordsData .record {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        .details {
            flex-direction: row;
            margin: 10rem -1.5rem 0 -1.5rem;
            & > div {
                flex: 1 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 0;
                padding: 0 1.5rem;
                h2 {
                    font-size: 2rem;
                    line-height: 2.8rem;
                }
            }
        }
        header{
            .playerStats{ order:2; max-width:calc(100% - 30rem); }
            .sportSelect{ order:3; flex:1 0 30rem; max-width:30rem; margin:3.5rem 0; justify-content:flex-end; }
        }
    }
    .mobileHeaderBackground {
        display: block;
    }
    .share {
        background-image: url('../../resources/images/ico-share.svg') !important;
    }
}

@media only screen and (max-width: 700px) {
    .player header{
        .playerStats{ order:3; max-width:100%; }
        .sportSelect{ order:2; flex:1 0 100%; max-width:100%; margin:3.5rem 0 0 0; justify-content:flex-start; }
    }
}

@media only screen and (max-width: 650px) {
    .player .details {
        flex-direction: column;
        & > div {
            flex: auto;
            max-width: 40rem;
            margin-bottom: 8rem;
        }
    }
}

@media only screen and (max-width: 550px) {
    .player {
        h2 {
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
        }
        header {
            flex-direction: column;
            align-items: center;
            .playerInfo {
                padding: 0;
                h1 {
                    margin-top: 1rem;
                    font-size: 3rem;
                    line-height: 3.6rem;
                    text-align: center;
                }
                ul {
                    justify-content: center;
                }
                .misc {
                    text-align: center;
                }
                ul.stats {
                    margin: 2.5rem -2rem 0 -1rem;
                    flex-wrap: wrap;
                    justify-content: center;
                    li {
                        margin: 0 0 2rem 0;
                        padding: 0 2rem;
                        text-align: center;
                        &:last-of-type {
                            padding-right: 2rem;
                        }
                    }
                }
                .contact {
                    align-items: center;
                    a {
                        display: inline-block;
                    }
                }
            }
            .scores {
                margin-top: 4rem;
            }
            .playerScore {
                display: flex;
                margin-bottom: 3rem;
                .score {
                    margin-top: 0;
                }
            }
            .playerStats {
                ul {
                    justify-content: center;
                    li {
                        font-size: 1.5rem;
                        padding: 1rem 1.7rem;
                    }
                }
            }
        }
        .trainingapp {
            flex-direction: column;
            margin-top: 4rem;
            padding: 2rem 2rem 6rem 2rem;
            background: linear-gradient(296deg, #c51f36 0%, #1e2f6e 100%);
            .img {
                flex: auto;
                max-width: 100%;
                margin-top: -4rem;
                img {
                    width: 18rem;
                    height: auto;
                    margin: 0 auto;
                }
            }
            .txt {
                flex: auto;
                max-width: 100%;
                padding: 0;
                margin-top: 2rem;
                p {
                    max-width: 30rem;
                }
            }
            .cta {
                flex: auto;
                max-width: 100%;
            }
        }
        .about {
            text-align: center;
        }
        .picture {
            flex: 1 0 12rem;
            max-width: 12rem;
            height: 12rem;
        }

        .details {
            text-align: center;
            align-items: center;
            & > div h2 {
                display: inline-block;
            }
        }

        .records .recordsData {
            .label {
                text-align: center;
            }
            .record {
                padding: 0 1.5rem;
                .speed .icon svg {
                    height: 1.8rem;
                }
                .agility .icon svg {
                    height: 1.6rem;
                }
                .explosion .icon svg {
                    height: 1.8rem;
                }
                h3 {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
                p {
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                }
                .icon {
                    height: 1.8rem;
                    margin: 0 0 0.2rem 0;
                }
                .label {
                    font-size: 1.1rem;
                    line-height: 1.4rem;
                    color: #808191;
                    margin: 0 auto;
                    max-width: 8rem;
                }
                & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                    font-size: 3rem;
                    line-height: 3rem;
                    margin: 1rem 0;
                    &:before {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin: 1rem 0 0 -2rem;
                    }
                }
                .play {
                    font-size: 0;
                    width: 3.8rem;
                    height: 3.8rem;
                    border-radius: 1.9rem;
                    padding: 0;
                    justify-content: center;
                    margin: 2rem 0 0 0;
                    span {
                        margin: 0 0 0 0.1rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .player .records .recordsData .record {
        flex: 1 0 50%;
        max-width: 50%;
    }
    .photosList .card {
        & > div:nth-of-type(2) {
            padding: 0 2rem 2rem 2rem;
        }
        .image {
            height: 21rem;
        }
        .meta > div > div {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}

@media only screen and (max-width: 380px) {
    .player {
        .records .recordsData {
            flex-direction: column;
            .record {
                flex: 1 0 100%;
                max-width: 100%;
                margin-bottom: 5rem;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .speed .icon svg {
                    height: 2.5rem;
                }
                .agility .icon svg {
                    height: 1.8rem;
                }
                .explosion .icon svg {
                    height: 1.9rem;
                }
                h3 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                }
                p {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }
                .icon {
                    height: 2.5rem;
                    margin: 0 0 0.5rem 0;
                }
                .label {
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    margin: 0 1rem;
                    max-width: 100%;
                }
                & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                    font-size: 3rem;
                    line-height: 3.5rem;
                    &:before {
                        width: 2rem;
                        height: 2rem;
                        margin: 1.5rem 0 0 -2.5rem;
                    }
                }
            }
        }
    }
}

.selected {
    background-color: #3f8cff !important;
    color: #fff !important;
}

.columnsFont {
    font-size: 17px;
}

.spinPlace {
    margin-left: 500px;
}

.seasons {
    width: 155px;
    height: 56px;
    border: 1px solid rgba(228, 228, 228, 0.1);
    border-radius: 12px;
    margin-right: 1rem;
    &>div{ background:transparent !important; }
    &>span{ background:transparent !important; }
}
