.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            // background-image: url('../../resources/images/headerEvents.jpg'); // loaded dynamically
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        button {
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid #555;
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 0 -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.login,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0 0 -1rem 0;
    .sports {
        margin: 0 0.8rem 0 0;
        display: flex;
        align-items: center;
    }
    button {
        height: 5.6rem;
        border-radius: 1.2rem;
        border: 1px solid #555;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 0 1rem -1px;
        &:hover,
        &[ant-click-animating-without-extra-node='true'] {
            background: #3f8cff;
            border: 1px solid #3f8cff !important;
            color: #fff;
        }
        &.upload {
            margin: 0 0 0 0.8rem;
            display: flex;
            align-items: center;
            span {
                width: 1.7rem;
                height: 1.7rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0.7rem 0 0;
                svg {
                    display: block !important;
                    width: 1.7rem;
                    height: 1.7rem;
                    z-index: 3;
                    color: transparent;
                }
            }
        }
    }
}

.player {
    position: relative;
    header {
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        .picture {
            flex: 1 0 25.6rem;
            max-width: 25.6rem;
            height: 25.6rem;
            & > div {
                width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .playerInfo {
            flex: 1;
            padding: 0 2rem;
            h1 {
                font-size: 4.6rem;
                line-height: 5rem;
                color: #fff;
                font-weight: 600;
                margin: 4.5rem 0 0 0;
            }
            // ul {
            //     display: flex;
            //     justify-content: flex-start;
            //     list-style: none;
            //     margin: 0.5rem 0 1rem 0;
            //     padding: 0;
            //     li {
            //         padding: 0 1.5rem 0 1.3rem;
            //         position: relative;
            //         color: #808191;
            //         font-size: 1.4rem;
            //         line-height: 1.6rem;
            //         white-space: nowrap;
            //         &:before {
            //             content: '';
            //             display: block;
            //             width: 0.8rem;
            //             height: 0.8rem;
            //             position: absolute;
            //             left: 0;
            //             top: 0.4rem;
            //             border-radius: 50%;
            //         }
            //         &.place:before {
            //             background: #0049c6;
            //         }
            //         &.height:before {
            //             background: #7fba7a;
            //         }
            //         &.weight:before {
            //             background: #2b9cc5;
            //         }
            //     }
            // }
            ul.stats {
                padding: 0;
                margin: 2.5rem 0 0 0;
                list-style: none;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                li {
                    padding: 0 2.5rem 0 0;
                    margin: 0 2rem 0 0;
                    border-right: 1px solid rgba(228, 228, 228, 0.3);
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    white-space: nowrap;
                    font-weight: 500;
                    label {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                        display: block;
                        text-transform: uppercase;
                        color: #808191;
                        margin: 0 0 0.5rem 0;
                        font-weight: 600;
                    }
                    &:last-of-type {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
            }
            .misc {
                p {
                    margin: 1rem 0 0 0;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
            }
            .school {
                margin: 3rem 0 0 0;
                font-size: 1.7rem;
                line-height: 2.5rem;
                color: #fff;
                font-weight: 300;
            }
        }
        .scores {
            display: flex;
            justify-content: flex-end;
        }
        .sportSelect{ flex:1 0 100%; max-width:100%; margin: 2.5rem 0 0 0; display:flex; justify-content:flex-end;
            .affiliates {
                width: 300px;
                height: 56px;
                border: 1px solid rgba(228, 228, 228, 0.1);
                border-radius: 12px;
            }
        }
        .playerScore {
            text-align: center;
            &:nth-of-type(1) {
                margin-right: 6rem;
            }
            .score {
                margin: 3rem 0 0 0;
                font-size: 8.3rem;
                line-height: 8rem;
                font-weight: 600;
                color: rgba(128, 129, 145, 0.6);
            }
            p {
                font-size: 1.7rem;
                margin: 0.5rem 0;
                line-height: 2.4rem;
                color: #fff;
                font-weight: 600;
            }
            img {
                display: inline-block;
            }
        }
    }

    .about {
        margin-top: 1rem;
        p {
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: #fff;
            margin: 2rem 0;
            max-width: 67rem;
            font-weight: 300;
        }
        a {
            font-size: 1.6rem;
            line-height: 2.6rem;
            text-decoration: underline;
            color: rgba(255, 255, 255, 0.4);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .records {
        header {
            margin: 7rem 0 3rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                margin: 0;
            }
            button {
                color: #808191;
                text-decoration: underline;
                padding: 0;
                margin: 0;
                border: none;
                &:hover {
                    text-decoration: none;
                    background: transparent;
                }
            }
        }
        .recordsData {
            margin: 6rem 0 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            border-radius: 2.4rem;
            background: #242731;
            padding: 2.5rem 0 0 0;
            overflow: hidden;
            & > .label {
                flex: 1 0 100%;
                max-width: 100%;
                padding: 0 2.5rem;
                & > div {
                    background: #33363f;
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    margin: 0 0 3rem 0;
                    padding: 0.8rem 2.2rem;
                    border-radius: 1.6rem;
                    font-weight: 500;
                }
            }
            .record {
                flex: 1 0 33.33%;
                max-width: 33.33%;
                text-align: center;
                padding: 0 3rem 1rem 3rem;
                position: relative;
                margin: 0 0 4.5rem 0;
                &:after {
                    content: '';
                    width: 1px;
                    height: 10rem;
                    background: #3b3e48;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    margin-top: -5rem;
                }

                .icon {
                    height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 0.5rem 0;
                }
                h3 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1.9px;
                    margin: 0;
                }
                .speed .icon svg {
                    width: auto;
                    height: 2.5rem;
                    color: transparent;
                }
                .agility .icon svg {
                    width: auto;
                    height: 1.8rem;
                    color: transparent;
                }
                .explosion .icon svg {
                    width: auto;
                    height: 1.9rem;
                    color: transparent;
                }

                p {
                    font-size: 2.1rem;
                    line-height: 2.4rem;
                    margin: 2rem 0 0 0;
                    font-weight: 500;
                    color: #fff;
                }
                & > div > div > span {
                    display: block;
                }
                & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                    font-family: Poppins, sans-serif;
                    font-size: 3.4rem;
                    line-height: 3.5rem;
                    margin: 2rem 0;
                    color: #fff;
                    font-weight: 600;
                    position: relative;
                }
                .label {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: #fff;
                    display: block;
                }
                .play {
                    height: 4.4rem;
                    background: #3f8cff url('../../resources/images/ico-play.svg') no-repeat 1.3rem center;
                    background-size: auto 1.4rem;
                    border-radius: 1.2rem;
                    display: inline-flex;
                    align-items: center;
                    margin: 2.5rem 0 0 0;
                    padding: 1rem 2.3rem;
                    transition: all 0.3s;
                    span {
                        width: 1.4rem;
                        height: 1.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 0.7rem 0 0;
                        svg {
                            display: none !important;
                            width: 1.4rem;
                            height: 1.4rem;
                            z-index: 3;
                            color: transparent;
                        }
                    }
                    &:hover {
                        background-color: #095cb5;
                    }
                }
            }
        }
    }

    .history {
        h2 {
            margin-top: 7rem;
        }
        .data {
            background: #242731;
            border-radius: 2.4rem;
            padding: 2.5rem 2.5rem 0 2.5rem;
            margin-bottom: 2.5rem;
            overflow: hidden;
            header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .label {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-right: 3rem;
                    flex: 1;
                    .logo {
                        height: 4.8rem;
                        width: 4.8rem;
                        max-width: 4.8rem;
                        flex: 1 0 4.8rem;
                        padding: 0.6rem;
                        border-radius: 50%;
                        background: #fff;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .txt {
                        padding-left: 1rem;
                        h3 {
                            font-size: 1.8rem;
                            line-height: 2.4rem;
                            font-weight: 500;
                            margin: 0;
                            color: #fff;
                        }
                        p {
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-weight: 600;
                            margin: 0;
                            color: #808191;
                        }
                    }
                }
                button {
                    height: 5.5rem;
                    border-radius: 1.2rem;
                    border: 1px solid rgba(228, 228, 228, 0.1);
                    display: flex;
                    align-items: center;
                    span {
                        width: 2rem;
                        height: 2rem;
                        margin-right: 1rem;
                        svg {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    &:hover {
                        background: #3f8cff;
                        color: #fff;
                        border: 1px solid #3f8cff;
                    }
                }
            }

            .records {
                margin: 6rem 0 0 0;
                h3 {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                    color: #fff;
                    margin: 0 0 3rem 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .nav {
                        padding-left: 2rem;
                        display: none;
                        button {
                            width: 4rem;
                            height: 4rem;
                            border: none;
                            padding: 0;
                            span {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: transparent;
                                svg {
                                    width: 4rem;
                                    height: 4rem;
                                }
                            }
                        }
                    }
                }
                .charts {
                    background: #242731;
                    border: 1px solid rgba(228, 228, 228, 0.1);
                    border-radius: 1.2rem;
                    padding: 2rem 1.8rem;
                    position: relative;
                    z-index: 2;
                    .chart {
                        margin: 0 0 0.5rem 0;
                        .labels {
                            display: flex;
                            justify-content: space-between;
                            padding-right: 14rem;
                        }
                        label {
                            font-size: 1.2rem;
                            line-height: 1.9rem;
                            text-transform: uppercase;
                            font-weight: 700;
                            color: #808191;
                            margin: 0 0 0 4.2rem;
                            position: relative;
                            display: flex;
                            align-items: center;
                            .pr {
                                width: 1.4rem;
                                height: 1.4rem;
                                background: #f0c52f;
                                display: inline-block;
                                border-radius: 50%;
                                color: #000;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                svg {
                                    width: 1rem;
                                    height: 1rem;
                                    opacity: 0.8;
                                }
                            }
                            .star {
                                display: inline-block;
                                width: 1.4rem;
                                height: 1.4rem;
                                background-size: contain;
                                background-position: center;
                                background-repeat: no-repeat;
                                margin-left: 0.8rem;
                                background-image: url('../../resources/images/ico-laser.png');
                            }
                            .check {
                                display: inline-block;
                                width: 1.5rem;
                                height: 1.6rem;
                                background-size: contain;
                                background-position: center;
                                background-repeat: no-repeat;
                                margin-left: 0.8rem;
                                background-image: url('../../resources/images/ico-check.png');
                            }
                        }
                        .progress {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            button.edit {
                                flex: 1 0 3.5rem;
                                max-width: 3.5rem;
                                height: 3.5rem;
                                background: #1f2128;
                                border: 1px solid rgba(228, 228, 228, 0.1);
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transition: all 0.3s;
                                span {
                                    svg {
                                        width: 1.6rem;
                                        height: 1.6rem;
                                        margin: -3px 0 0 2px;
                                        color: transparent;
                                        filter: invert(72%) sepia(72%) saturate(3718%) hue-rotate(225deg)
                                            brightness(101%) contrast(102%);
                                    }
                                }
                                &:hover {
                                    background-color: #3f8cff;
                                    span svg {
                                        filter: none;
                                    }
                                }
                            }
                            button.edit + div {
                                padding-left: 0.7rem;
                            }
                            button.media {
                                height: 3rem;
                                flex: 1 0 7.5rem;
                                max-width: 7.5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 1.2rem;
                                background: #3f8cff;
                                border: 1px solid #3f8cff;
                                font-size: 1.1rem;
                                line-height: 1.3rem;
                                font-weight: 500;
                                margin-left: 2rem;
                                transition: all 0.3s;
                                span {
                                    margin-right: 0.5rem;
                                    width: 1.2rem;
                                    height: 1.2rem;
                                    svg {
                                        width: 1.2rem;
                                        height: 1.2rem;
                                    }
                                }
                                &:hover {
                                    background-color: #095cb5;
                                    border-color: #095cb5;
                                }
                            }
                            button.play {
                                height: 3rem;
                                flex: 1 0 7.5rem;
                                max-width: 7.5rem;
                                padding: 0 0.5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 1.2rem;
                                background: #3f8cff;
                                border: 1px solid #3f8cff;
                                font-size: 1.1rem;
                                line-height: 1.3rem;
                                font-weight: 500;
                                margin-left: 2rem;
                                transition: all 0.3s;
                                span {
                                    margin-right: 0.5rem;
                                    width: 1.2rem;
                                    height: 1.2rem;
                                    svg {
                                        width: 1.2rem;
                                        height: 1.2rem;
                                    }
                                }
                                &:hover {
                                    background-color: #095cb5;
                                    border-color: #095cb5;
                                }
                            }
                            .progress {
                                font-size: 1.2rem;
                                font-weight: 500;
                                span {
                                    width: 4rem;
                                    color: #fff !important;
                                }
                                & > div > div {
                                    border-radius: 0;
                                    & > div {
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .recommendation {
                    background: #1f2128;
                    border: 1px solid rgba(228, 228, 228, 0.1);
                    border-radius: 1.2rem;
                    padding: 5rem 2.5rem;
                    margin-top: -2.5rem;
                    position: relative;
                    font-size: 1.3rem;
                    line-height: 2rem;
                    color: #808191;
                    span,
                    strong {
                        font-weight: 500;
                        color: #fff;
                    }
                }

                .navi {
                    margin: 3rem -2.6rem -0.1rem -2.5rem;
                    display: flex;
                    flex-wrap: wrap;
                    border-top: 1px solid #2d2e33;
                    button {
                        border: none;
                        border-right: 1px solid #2d2e33;
                        border-bottom: 1px solid #2d2e33;
                        flex: 1 0 25%;
                        max-width: 25%;
                        padding: 2.3rem 2.5rem;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        transition: all 0.3s;
                        &:hover,
                        &[ant-click-animating-without-extra-node='true'] {
                            background: #1f2128;
                            border-right: 1px solid #2d2e33 !important;
                            border-bottom: 1px solid #2d2e33 !important;
                        }
                        &.tenYard .ico span svg {
                            width: 2.6rem;
                            height: 2.6rem;
                        }
                        &.thirtyYard .ico span svg {
                            width: 2.6rem;
                            height: 2.6rem;
                        }
                        &.fivetenYard .ico span svg {
                            width: 2.9rem;
                            height: 2.1rem;
                            margin-left: 1px;
                        }
                        &.verticalJump .ico span svg {
                            width: 1.5rem;
                            height: 1.9rem;
                            margin-left: 1px;
                            margin-top: -1px;
                        }
                        &.velo .ico span svg {
                            width: 3rem;
                            height: 1.8rem;
                            margin-top: -2px;
                        }
                        &.bat .ico span svg {
                            width: 3rem;
                            height: 2.8rem;
                            margin-top: -2px;
                        }

                        .ico {
                            width: 4.2rem;
                            height: 4.2rem;
                            flex: 1 0 4.2rem;
                            max-width: 4.2rem;
                            border-radius: 50%;
                            background: #1f2128;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: transparent;
                            border: 1px solid #2d2e33;
                        }
                        .txt {
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            color: #808191;
                            font-weight: 600;
                            text-align: left;
                            padding-left: 1.4rem;
                            flex: 1;
                            max-width: calc(100% - 4.2rem);
                            display: flex;
                            flex-direction: column;
                            white-space: initial;
                            .value {
                                color: #fff;
                                font-size: 1.8rem;
                                line-height: 2.4rem;
                                font-weight: 500;
                                display: block;
                                white-space: initial;
                                .star,
                                .check {
                                    display: inline-block;
                                    width: 1.3rem;
                                    height: 1.3rem;
                                    background-size: contain;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    margin-left: 0.8rem;
                                }
                                .star {
                                    background-image: url('../../resources/images/ico-laser.png');
                                }
                                .check {
                                    background-image: url('../../resources/images/ico-check.png');
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .stats {
        padding-bottom: 8rem;
        h2 {
            margin-top: 7rem;
        }
        textarea {
            background: #242731;
            border-radius: 2.4rem;
            border: none;
            padding: 2rem 2rem;
        }
    }

    h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        color: #fff;
        margin: 3rem 0;
    }
}

/* mobile */
.mobileHeaderBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 33rem;
    display: none;
    .headerBackgroundImage {
        background-image: url('../../resources/images/headerEvents-mob.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.topNavi {
    display: flex;
    justify-content: space-between;
    margin: 0 -0.5rem 2.5rem -0.5rem;
    .buttonDiv {
        flex: 1 0 50%;
        max-width: 50%;
        padding: 0 0.5rem;
        button {
            border: none;
            width: 100%;
            height: 5.6rem;
            background: #242731;
            color: #a0a1ab;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.8rem 2rem 1.8rem 5.6rem;
            position: relative;
            text-align: left;
            font-weight: 500;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: '';
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                left: 1.7rem;
                top: 0;
                width: 3rem;
                height: 100%;
                opacity: 0.4;
            }
        }
        .events:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-document.svg');
            background-size: 1.8rem auto;
            background-position: 0.6rem center;
        }
        .tasks:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-tasks.svg');
            background-size: 3rem auto;
            background-position: center;
        }
    }
}

/* rwd */
@media only screen and (min-width: 1450px) {
    .photosList > div > div > div {
        & > div:nth-of-type(6n + 1) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        & > div:nth-of-type(6n + 2) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 3) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 4) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 5) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(6n + 6) {
            flex: 1 0 40%;
            max-width: 40%;
        }
    }
}

@media only screen and (min-width: 1700px) {
    .photosList > div > div > div {
        & > div:nth-of-type(8n + 1) {
            flex: 1 0 34%;
            max-width: 34%;
        }
        & > div:nth-of-type(8n + 2) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 3) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 4) {
            flex: 1 0 22%;
            max-width: 22%;
        }

        & > div:nth-of-type(8n + 5) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 6) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 7) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(8n + 8) {
            flex: 1 0 34%;
            max-width: 34%;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .player header .playerScore {
        .score {
            font-size: 7rem;
            line-height: 7rem;
        }
        p {
            font-size: 1.6rem;
        }
        &:nth-of-type(1) {
            margin-right: 4rem;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .player header {
        flex-wrap: wrap;
        justify-content: flex-start;
        .scores {
            flex: 1 0 100%;
            max-width: 100%;
            justify-content: flex-start;
        }
        .sportSelect{ justify-content:flex-start; margin:4.5rem 0 0 0; }
    }
}

@media only screen and (max-width: 1200px) {
    .player .records .recordsData {
        padding: 3rem 0;
    }
}

@media only screen and (max-width: 1150px) {
    .player .history .data .records .navi button {
        flex: 1 0 33.33%;
        max-width: 33.33%;
    }
}

@media only screen and (max-width: 1100px) {
    .player header {
        .playerInfo h1 {
            margin-top: 3.5rem;
        }
        .picture {
            flex: 1 0 18rem;
            max-width: 18rem;
            height: 18rem;
        }
        .scores {
            margin-top: 0;
            justify-content: flex-start;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .player .records .recordsData {
        .record {
            p {
                font-size: 1.8rem;
                line-height: 2.2rem;
            }
            .label {
                font-size: 1.3rem;
                line-height: 1.6rem;
            }
            & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                font-size: 2.8rem;
                line-height: 3rem;
                &:before {
                    width: 2rem;
                    height: 2rem;
                    margin: 1.5rem 0 0 -2.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .player .records .recordsData .record {
        flex: 1 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 900px) {
    .player .history .data .records .navi button {
        flex: 1 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .mobileHeaderBackground {
        display: block;
    }
    .player .records .recordsData .record {
        flex: 1 0 33.33%;
        max-width: 33.33%;
    }
    .player .history .data .records .navi button {
        flex: 1 0 33.33%;
        max-width: 33.33%;
    }
}

@media only screen and (max-width: 650px) {
    .player .history .data .records .navi button {
        flex: 1 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .player .history .data {
        header {
            flex-wrap: nowrap;
            flex-direction: row;
            button {
                font-size: 0;
                height: 4rem;
                padding: 0.4rem 1.1rem;
                flex: 1 0 4.4rem;
                max-width: 4.4rem;
                margin-top: 0.4rem;
                span {
                    margin-right: 0;
                }
            }
        }
        .records {
            .charts .chart {
                .labels {
                    padding-right: 10rem;
                }
                .progress {
                    button.play,
                    button.media {
                        font-size: 0;
                        flex: 1 0 3.5rem;
                        max-width: 3.5rem;
                        height: 3.5rem;
                        border-radius: 50%;
                        span {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .player {
        h2 {
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
        }
        header {
            flex-direction: column;
            align-items: center;
            .playerInfo {
                padding: 0;
                h1 {
                    margin-top: 1rem;
                    font-size: 3rem;
                    line-height: 3.6rem;
                    text-align: center;
                }
                ul {
                    justify-content: center;
                }
                .misc {
                    text-align: center;
                }
                ul.stats {
                    margin: 2.5rem -2rem 0 -1rem;
                    flex-wrap: wrap;
                    justify-content: center;
                    li {
                        margin: 0 0 2rem 0;
                        padding: 0 2rem;
                        text-align: center;
                        &:last-of-type {
                            padding-right: 2rem;
                        }
                    }
                }
            }
            .playerScore {
                display: flex;
                margin-bottom: 3rem;
                .score {
                    margin-top: 0;
                }
            }
        }
        .about {
            text-align: center;
        }
        .picture {
            flex: 1 0 12rem;
            max-width: 12rem;
            height: 12rem;
        }

        .records {
            header h2 {
                margin-bottom: 2rem;
            }
            .recordsData {
                .label {
                    text-align: center;
                }
                .record {
                    padding: 0 1.5rem;
                    .speed .icon svg {
                        height: 1.8rem;
                    }
                    .agility .icon svg {
                        height: 1.6rem;
                    }
                    .explosion .icon svg {
                        height: 1.8rem;
                    }
                    h3 {
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                    p {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                    }
                    .icon {
                        height: 1.8rem;
                        margin: 0 0 0.2rem 0;
                    }
                    .label {
                        font-size: 1.1rem;
                        line-height: 1.4rem;
                        color: #808191;
                        margin: 0 auto;
                        max-width: 8rem;
                    }
                    & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                        font-size: 2rem;
                        line-height: 2.2rem;
                        &:before {
                            width: 1.6rem;
                            height: 1.6rem;
                            margin: 1rem 0 0 -2rem;
                        }
                    }
                    .play {
                        font-size: 0;
                        width: 3.8rem;
                        height: 3.8rem;
                        border-radius: 1.9rem;
                        padding: 0;
                        justify-content: center;
                        margin: 2rem 0 0 0;
                        span {
                            margin: 0 0 0 0.1rem;
                        }
                    }
                }
            }

            .history .data .records .navi button {
                padding: 2.1rem 2.2rem;
                .txt {
                    font-weight: 400;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .player .records .recordsData .record {
        flex: 1 0 50% !important;
        max-width: 50% !important;
    }
}

@media only screen and (max-width: 420px) {
    .player {
        .history .data {
            padding: 1.5rem 1.5rem 0 1.5rem;
            .records {
                .charts {
                    padding: 2rem 1.5rem;
                }
                .recommendation {
                    padding: 5rem 1.5rem;
                }
                .navi {
                    margin: 2.5rem -1.6rem -0.1rem -1.5rem;
                    button {
                        padding: 2rem 1.5rem;
                        .txt {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .player {
        .records .recordsData {
            flex-direction: column;
            .record {
                flex: 1 0 100% !important;
                max-width: 100% !important;
                margin-bottom: 5rem;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .speed .icon svg {
                    height: 2.5rem;
                }
                .agility .icon svg {
                    height: 1.8rem;
                }
                .explosion .icon svg {
                    height: 1.9rem;
                }
                h3 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                }
                p {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }
                .icon {
                    height: 2.5rem;
                    margin: 0 0 0.5rem 0;
                }
                .label {
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    margin: 0 1rem;
                    max-width: 100%;
                }
                & > div > div:nth-of-type(2) > span:nth-of-type(1) {
                    font-size: 3rem;
                    line-height: 3.5rem;
                    &:before {
                        width: 2rem;
                        height: 2rem;
                        margin: 1.5rem 0 0 -2.5rem;
                    }
                }
            }
        }
        .history .data .records .navi button {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .player {
        .history .data .records h3 .nav {
            display: flex;
        }
        .records .recordsData .record {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
    }
    .mobileHeaderBackground {
        display: block;
    }
    .share {
        background-image: url('../../resources/images/ico-share.svg') !important;
    }
    .login {
        background-image: none !important;
    }
}

.selected {
    background-color: #3f8cff !important;
    color: #fff !important;
}

.resultsSelected {
    background-color: #1f2128 !important;
    color: #fff !important;
}

.emptyItem {
    height: 3rem;
    flex: 1 0 7.5rem;
    max-width: 7.5rem;
    padding: 0 0.5rem;
    display: flex;
    margin-left: 2rem;
}

.emptyItemMobile {
    font-size: 0;
    flex: 1 0 3.5rem;
    max-width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    margin-left: 2rem;
}

.emptyEditButton {
    flex: 1 0 3.5rem;
    max-width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

.seasons {
    width: 155px;
    height: 56px;
    border: 1px solid rgba(228, 228, 228, 0.1);
    border-radius: 12px;
    margin-right: 1rem;
    &>div{ background:transparent !important; }
    &>span{ background:transparent !important; }
}

.affiliates {
    float: right;
    width: 300px;
    height: 56px;
    border: 1px solid rgba(228, 228, 228, 0.1);
    border-radius: 12px;
}
